import React from "react";

import { Button, Div, Icon, Image, Text } from "atomize";
import { useForm } from "../../hooks/useForm";

import { Oval } from "react-loader-spinner";
import { FormProvider } from "react-hook-form";
import InputField from "../../components/form/InputField";
import { registerUsersSchema } from "../../yup/schemas";
import { client } from "../../lib/client";
import { toast } from "react-hot-toast";
import SuccessSvg from "../../images/success-svg.svg";
import { navigate } from "gatsby";

const RegisterForm = ({ setIsShowingConfetti, isShowingConfetti }) => {
  const formMethods = useForm({
    schema: registerUsersSchema,
    defaultValues: {
      email: "",
      password: "",
      confirm_password: "",
      name: "",
      lastname: "",
      phone: "",
    },
  });

  const onSubmit = async ({ confirm_password, ...data }) => {
    try {
      await client.post("/signup", data);
      toast.success("¡Registro exitoso! Revisa tu correo para continuar");

      formMethods.reset();
      setIsShowingConfetti(true);

      setTimeout(() => {
        setIsShowingConfetti(false);
        navigate("/dash/login");
      }, 2000);
    } catch (e) {
      toast.error(
        e?.response?.data?.message ?? "Algo ha salido mal, verifica tus datos"
      );
      throw e;
    }
  };
  const {
    formState: { isSubmitting },
  } = formMethods;

  if (isShowingConfetti) {
    return (
      <Div
        d="flex"
        flexDir="column"
        border="1px solid"
        borderColor="gray200"
        w={{ xs: "100%", md: "19.5rem" }}
        maxW="100%"
        pos={{ xs: "static", md: "absolute" }}
        m={{ xs: "1rem", md: "0" }}
        right="0"
        top="-10rem"
        rounded="xl"
        h={{ lg: "40rem" }}
        justify="center"
        shadow="4"
        align="center"
        p="2rem"
      >
        <Image
          src={SuccessSvg}
          alt="atomize design system logo"
          h="100px"
          w="auto"
        />
        <Div
          d="flex"
          align="center"
          p="1rem"
          textColor="black"
          textSize="title"
        >
          ¡Gracias por registrarte a File Hire! Se te enviará un email con
          instrucciones.
        </Div>
      </Div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Div
        d="flex"
        flexDir="column"
        border="1px solid"
        borderColor="gray200"
        w={{ xs: "100%", md: "36rem" }}
        maxW="100%"
        right="0"
        top="-10rem"
        rounded="xl"
        bg="white"
        shadow="4"
        p="2rem"
      >
        <Div>
          <Text
            textAlign="center"
            textSize="title"
            m={{ t: "0rem", b: "0.5rem" }}
            textWeight="600"
            textColor={"#17319D"}
            fontFamily="Anuphan"
          >
            Regístrate hoy
          </Text>
          <Text
            textColor="light"
            textSize="caption"
            m={{ b: "1rem" }}
            textAlign="center"
          ></Text>
          <InputField
            nameField="name"
            type="text"
            rounded={"lg"}
            _container={{
              m: { b: "0.1rem" },
              p: { x: "1rem" },
            }}
            placeholder="Nombre"
            suffix={
              <Icon
                pos="absolute"
                name="Rename"
                color="light"
                size="18px"
                top="50%"
                transform="translateY(-50%)"
                right="1rem"
              />
            }
          />
          <InputField
            nameField="lastname"
            type="text"
            _container={{
              m: { b: "0.1rem" },
              p: { x: "1rem" },
            }}
            placeholder="Apellido"
            suffix={
              <Icon
                pos="absolute"
                name="Rename"
                color="light"
                size="18px"
                top="50%"
                transform="translateY(-50%)"
                right="1rem"
              />
            }
          />

          <InputField
            type="text"
            nameField="phone"
            _container={{
              m: { b: "0.1rem" },
              p: { x: "1rem" },
            }}
            placeholder="+52 Teléfono"
            suffix={
              <Icon
                pos="absolute"
                name="Rename"
                color="light"
                size="18px"
                top="50%"
                transform="translateY(-50%)"
                right="1rem"
              />
            }
          />

          <InputField
            type="email"
            nameField="email"
            _container={{
              m: { b: "0.1rem" },
              p: { x: "1rem" },
            }}
            placeholder="johndoe@gmail.com"
            suffix={
              <Icon
                pos="absolute"
                name="Email"
                color="light"
                size="18px"
                top="50%"
                transform="translateY(-50%)"
                right="1rem"
              />
            }
          />
          <InputField
            nameField="password"
            type="password"
            _container={{
              m: { b: "0.1rem" },
              p: { x: "1rem" },
            }}
            placeholder="Contraseña"
            suffix={
              <Icon
                pos="absolute"
                name="Lock"
                color="light"
                size="18px"
                top="50%"
                transform="translateY(-50%)"
                right="1rem"
              />
            }
          />
          <InputField
            nameField="confirm_password"
            type="password"
            _container={{
              m: { b: "2rem" },
              p: { x: "1rem" },
            }}
            placeholder="Confirmar contraseña"
            suffix={
              <Icon
                pos="absolute"
                name="Lock"
                color="light"
                size="18px"
                top="50%"
                transform="translateY(-50%)"
                right="1rem"
              />
            }
          />
        </Div>
        <Button
          rounded="circle"
          h="3rem"
          bg="info200"
          hoverBg="info300"
          textColor="info700"
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          {isSubmitting ? (
            <Oval
              height={20}
              width={20}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <div> Registrar</div>
          )}
        </Button>
      </Div>
    </FormProvider>
  );
};

export default RegisterForm;
