import React from "react";

const HeroWave = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={2000}
    height={324}
    fill="none"
    {...props}
  >
    <path
      fill="#080F43"
      d="M76.337 217.45c-30.535-9.043-47.352-13.596-74.81-17.396V324H1998.47V109.812c-71.45 43.225-117.85 63.525-216.79 89.698-115.92 26.542-194.45 26.542-354.2 4.893-172.88-22.118-261.09-28.289-395.42-22.833-139.459 9.516-225.926 23.321-388.548 56.537-89.953 15.726-139.456 21.389-225.191 22.833-137.816 2.273-211.811-2.334-341.984-43.49Z"
    />
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M295.646 97.026C193.314 91.861 99.876 83.296 2.292 54.528v146.313c26.583 3.758 43.35 8.31 73.338 17.183 130.272 41.156 204.324 45.763 342.246 43.49 85.802-1.444 135.342-7.108 225.363-22.833 162.746-33.216 249.279-47.021 388.851-56.536 134.43-5.456 222.71.714 395.72 22.832 159.88 21.649 238.45 21.649 354.47-4.893 99.02-26.173 145.45-46.473 216.96-89.698V40.259h-150.5l-55-1.088-42.02-2.718-42.78-2.718-281.9-23.92C1123.87-10.961 970.546.126 721.925 53.85c-167.916 36.465-323.147 48.383-426.279 43.177Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={999.236}
        x2={1014.34}
        y1={-83.174}
        y2={434.784}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.355} stopColor="#B371B5" />
        <stop offset={0.818} stopColor="#303CAA" stopOpacity={0.8} />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h2000v262.027H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default HeroWave;
