import React from "react";
import HeroImage from "../../images/banner.png";

const HeroSection = () => {
  return (
    <section
      className={"grid grid-cols-1 md:grid-cols-8 items-center "}
      id={"inicio"}
    >
      <div className="col-span-12 lg:col-span-5">
        <div
          className={
            "flex flex-col gap-6 items-center lg:items-start text-center heroText lg:absolute lg:top-[25%] lg:left-[10%]"
          }
        >
          <h1
            className={"text-6xl md:text-8xl lg:text-9xl font-bold text-white"}
          >
            FileHire
          </h1>
          <p
            className={
              "text-xl md:text-3xl lg:text-3xl text-white font-bold leading-6  lg:text-start"
            }
          >
            Aplica a cientos de vacantes
            <br />
            de trabajo en México al instante.
          </p>
          <div>
            <a
              href={"#registerForm"}
              className={
                "bg-white text-[#17319D] py-2 px-9 rounded-3xl font-bold text-2xl"
              }
            >
              Comienza ahora
            </a>
          </div>
        </div>
      </div>

      <div
        className="bg-transparent md:col-span-3 hidden lg:inline"
        style={{ zIndex: 100 }}
      >
        <img src={HeroImage} alt={"banner"} className={"floating"} />
      </div>
    </section>
  );
};

export default HeroSection;
