import * as React from "react";
const EducationLevelWave = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={2000}
    height={209}
    fill="none"
    {...props}
  >
    <path
      fill="#0A1D6C"
      fillRule="evenodd"
      d="m1998.85 0-15.62 7.57c-87.24 42.268-168.07 59.819-259.47 56.339-47.88-1.823-85.17-6.648-180.67-23.377-91.09-15.954-122.16-20.714-169.34-25.938-54.82-6.068-85.11-7.887-131.27-7.879-49.8.008-83.27 2.476-147.29 10.858-6.34.83-14.68 1.919-18.54 2.421-33.67 4.376-77.799 12.411-115.227 20.982-30.503 6.986-49.581 11.951-126.253 32.855-68.542 18.687-101.775 26.588-141.282 33.586-60.26 10.674-115.699 14.941-194.89 14.997-58.929.042-77.982-1.338-123.247-8.926-51.186-8.581-106.61-25.835-163.827-51.002-39.075-17.187-74.361-27.353-110.722-31.901-19.994-2.5-44.536-3.813-58.617-3.136-15.298.736-35.91 2.37-39.83 3.157L0 31.159V209h2000V82.5c0-31.447-.05-60.31-.12-82.5h-1.03Z"
      clipRule="evenodd"
    />
  </svg>
);
export default EducationLevelWave;
