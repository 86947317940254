import React from "react";
import HandshakeVector from "../../images/HandShakeIcon.svg";
import FormIconVector from "../../images/FormIcon.svg";
import IdeaIconVector from "../../images/IdeaIcon.svg";
import Bubble from "../../images/burbuja_5.svg";
import { Card } from "./index";
import FinJobWave from "./svgs/FinJobWave";

const FindJobSection = () => {
  return (
    <div>
      <div
        className={
          "bg-gradient-to-t from-[#4A3995] to-[#080F43] flex flex-col justify-center items-center"
        }
      >
        <div
          className={
            "w-[80%] mx-auto text-white flex flex-col gap-10 md:gap-32 lg:gap-20 fade-in"
          }
        >
          <div
            className={
              "flex flex-col text-center justify-center lg:flex lg:flex-row"
            }
          >
            <p
              className={
                "text-3xl md:text-4xl lg:text-5xl font-bold text-center"
              }
            >
              Encuentra tu siguiente trabajo
            </p>
            <div className={"flex justify-center"}>
              <p className={"text-5xl"}>⚡</p>
              <p className={"text-yellow-300 font-bold text-5xl"}>rápido</p>
            </div>
          </div>

          <div
            className={
              "flex flex-col lg:flex lg:flex-row justify-evenly mt-28 text-center gap-40 md:gap-44 lg:gap-20 pb-28"
            }
          >
            <div className={"relative"}>
              <div
                className={
                  "absolute hidden md:inline  md:-top-20 md:-left-20 lg:-top-20 lg:-left-20 floating"
                }
              >
                <img src={Bubble} width={150} alt={"Bubble_Card_image"} />
              </div>
              <Card
                title={"Regístrate y completa tu perfil"}
                image={FormIconVector}
                altImage={"Educacion"}
              />
            </div>
            <Card
              title={"Elige las vacantes ideales para ti"}
              image={IdeaIconVector}
              altImage={"Educacion"}
            />
            <Card
              title={"Aplica a cientos de trabajos al instante"}
              image={HandshakeVector}
              altImage={"Educacion"}
            />
          </div>
        </div>
        <div className={"relative"}>
          {""}
          <FinJobWave />
        </div>
      </div>
    </div>
  );
};

export default FindJobSection;
