import React from "react";
import { RegisterForm } from "./index";
import RegisterAirPlane from "../../images/avion_2.svg";
import BubbleRegisterTop from "../../images/burbuja_3.svg";
import BubbleRegisterBottom from "../../images/burbuja_4.svg";
import { CommentSliderWave } from "./svgs";

const RegisterFormSection = ({ setIsShowingConfetti, isShowingConfetti }) => {
  return (
    <div
      className={"bg-gradient-to-b from-[#B666B7] to-[#2C1645] text-white"}
      id={"registerForm"}
    >
      <div
        className={
          "w-[80%] mx-auto flex flex-col-reverse justify-center items-center lg:flex lg:flex-row gap-24 py-20"
        }
      >
        <div
          className={
            "absolute md:w-[50%] md:top-[220%] md:-left-20 lg:w-[18%] lg:top-[166%] lg:-left-20 floating"
          }
        >
          <img src={BubbleRegisterTop} alt={"Bubble_register_image"} />
        </div>
        <div
          className={"absolute lg:w-[18%] lg:top-[240%] lg:left-[92%] floating"}
        >
          <img src={BubbleRegisterBottom} alt={"Bubble_register_image"} />
        </div>
        <div>
          <RegisterForm {...{ setIsShowingConfetti, isShowingConfetti }} />
        </div>
        <div className={"w-full flex flex-col gap-4 px-2"}>
          <div className={"flex flex-col gap-4 text-center"}>
            <h1
              className={
                "text-3xl md:text-4xl lg:text-5xl font-regular lg:text-start"
              }
            >
              Conecta con cientos <br />
              de empleadores ahora.
            </h1>
            <p
              className={
                "text-xl md:text-2xl lg:text-2xl lg:text-start font-light"
              }
            >
              Regístrate en segundos y encuentra tu trabajo ideal de forma rápida y fácil
              <br /> ¡Prueba la plataforma hoy mismo GRATIS!
            </p>
          </div>

          <div className={"lg:w-[70%]"}>
            <img
              src={RegisterAirPlane}
              alt={"Register AirPlane"}
              width={"100px"}
              style={{ rotate: "350deg" }}
              className={"mx-auto"}
            />
          </div>
        </div>
      </div>
      <div>
        <CommentSliderWave />
      </div>
    </div>
  );
};

export default RegisterFormSection;
