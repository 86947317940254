import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";

const NavItem = ({ variant = "default", label, className, ...props }) => {
  return (
    <Link
      to={props.href}
      className={classNames(
        {
          "border-white border rounded-3xl text-white text-md flex justify-center items-center w-full text-center py-1 px-4":
            variant === "bordered",
          "text-white": variant === "default",
        },
        className
      )}
      {...props}
    >
      {label}
    </Link>
  );
};

export default NavItem;
