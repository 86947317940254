import React from "react";

const Card = ({ altImage, description = "", image, title }) => {
  return (
    <div
      className={
        "bg-gradient-to-b from-purple-950 via-purple-800 to-purple-950 p-10 flex flex-col justify-center items-center rounded-xl"
      }
      style={{ zIndex: 200 }}
    >
      <div>
        <h3 className={"text-3xl font-bold "}>{title}</h3>
      </div>
      <div className={"p-2"}>
        <img src={image} alt={altImage} className={"w-80 h-60"} />
      </div>

      <div>
        <p className={"text-2xl"}>{description}</p>
      </div>
    </div>
  );
};

export default Card;
