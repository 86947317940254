import React from "react";

const Card = ({ altImage, image, title }) => {
  return (
    <div
      className={
        "bg-gradient-to-b from-purple-500 via-purple-600 to-purple-800 px-6 py-10 flex flex-col gap-6 rounded-xl"
      }
    >
      <img
        src={image}
        alt={altImage}
        className={"w-full h-60 -mt-40 lg:-mt-40 "}
      />
      <div className={"md:py-8"}>
        <h3 className={"text-2xl lg:text-3xl font-bold "}>{title}</h3>
      </div>
    </div>
  );
};

export default Card;
