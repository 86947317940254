import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { EducationLevelWave } from "./svgs";
import BubbleComment from "../../images/burbuja_quote.svg";
import Quote from "../../images/Quote.svg";
import QuoteReverse from "../../images/QuoteReverse.svg";

const CommentSliderSection = () => {
  return (
    <div className={"bg-gradient-to-b from-[#6A55BE] to-[#0F0539] "}>
      <div
        className={
          "w-[80%] mx-auto py-20 text-center text-white relative lg:h-[50vh] flex flex-col justify-center"
        }
      >
        <div
          className={
            "absolute md:w-[40%] lg:w-[20%] right-0 md:top-8 lg:top-28 floating"
          }
        >
          <img src={BubbleComment} alt={"Bubble Comment Image"} />
        </div>
        <Swiper
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          slidesPerView={1}
        >
          <div className={"relative"}>
            <div
              className={
                "p-4 absolute w-[30%] -top-60 -left-10 md:-top-72 md:-left-10 lg:left-20 lg:-top-60"
              }
            >
              <img src={Quote} alt={"Quote"} />
            </div>
          </div>
          <SwiperSlide>
            <h1 className={"font-extrabold text-xl md:text-3xl lg:text-3xl"}>
              Apliqué a 112 vacantes de project manager en 15 minutos. <br />
              Simplemente funciona
            </h1>
            <p className={"text-2xl font-light"}>Rodrigo, 28 años </p>
          </SwiperSlide>
          <div className={"relative"}>
            <div
              className={
                "p-4 absolute right-0 md:-right-[70%] lg:-right-[84%] w-[30%] md:w-full"
              }
            >
              <img src={QuoteReverse} alt={"QuoteReverse"} />
            </div>
          </div>
        </Swiper>
      </div>
      <div>
        <EducationLevelWave />
      </div>
    </div>
  );
};

export default CommentSliderSection;
