import React, { useEffect, useMemo, useState } from "react";
import NavItem from "./NavItem";
import FilehireLogo from "../../images/logo-header.svg";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const menuItems = [
  {
    label: "Regístrate",
    url: "#registerForm",
  },
  // {
  //   label: "Blog",
  //   url: "/blog",
  // },
  // {
  //   label: "Nosotros",
  //   url: "#about",
  // },
  // {
  //   label: "Contacto",
  //   url: "#contact",
  // },
  {
    label: "Iniciar sesión",
    url: "/dash/login",
    variant: "bordered",
  },
];
const Header = () => {
  const [open, setIsOpen] = useState(false);

  const [isScrolling, setIsScrolling] = useState(false);

  const handleMenu = () => {
    setIsOpen(!open);
  };

  useEffect(() => {
    const changeColor = (e) => {
      if (window.scrollY >= 90) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const listItems = useMemo(
    () =>
      menuItems.map(({ url, ...item }) => {
        return (
          <li key={item.label}>
            <NavItem href={url} {...item} />
          </li>
        );
      }),
    [isScrolling]
  );
  const color = isScrolling ? "rgba(1,1,1,1)" : "transparent";
  const textColor = isScrolling ? "#000000" : "#FFFFFF";

  return (
    <header
      style={{ backgroundColor: `${color}` }}
      className={
        "ease-in duration-300 z-10 fixed justify-between w-full top-0 left-0"
      }
    >
      <nav className={"w-[80%] mx-auto py-6 flex items-center justify-between"}>
        <a href={"#"} className={"flex"}>
          <img
            src={FilehireLogo}
            alt={"filehire logo"}
            className={"w-[100px] md:w-[125px] lg:w-full lg:inline md:mr-4"}
          />
        </a>
        <ul
          className={"hidden sm:flex gap-4 items-center text-lg"}
          style={{ color: `${textColor}` }}
        >
          {listItems}
        </ul>

        {/*  mobile Menu*/}
        <button onClick={handleMenu} className={"block sm:hidden z-10"}>
          {open ? (
            <AiOutlineClose size={20} style={{ fill: "white" }} />
          ) : (
            <AiOutlineMenu size={20} style={{ fill: "white" }} />
          )}
        </button>
        <div
          className={
            open
              ? "bg-gradient-to-r flex from-[#120F19] to-[#363FAC] sm:hidden absolute top-0 left-0 right-0 bottom-0 flex  flex-col gap-20 justify-center items-center w-full h-screen text-center text-xl ease-in duration-300"
              : "bg-gradient-to-r flex from-[#120F19] to-[#363FAC] sm:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen text-center ease-in duration-300"
          }
        >
          <ul>{listItems}</ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
