import React from "react";
import Education from "../../images/education.svg";
import findJob from "../../images/findJob.svg";
import money from "../../images/money.svg";
import BubbleFileHire from "../../images/burbuja_firehire.svg";
import BubbleEducationSection from "../../images/burbuja_5.svg";
import { CardEasy } from "./index";

const EducationLevelSection = () => {
  return (
    <div>
      <div
        className={
          "bg-gradient-to-b from-[#0A1D6C] to-[#060C23] flex flex-col justify-center items-center "
        }
      >
        <div className={"w-[80%] mx-auto text-white flex flex-col gap-14"}>
          <div
            className={
              "flex flex-col gap-14 lg:flex lg:flex-row lg:justify-center lg:items-center lg:gap-12"
            }
          >
            <h2
              className={
                "text-3xl md:text-4xl lg:text-5xl font-bold text-center"
              }
            >
              Simplifica tu búsqueda de trabajo con
            </h2>
            <div className={"w-[100vw] lg:w-52 -mt-4 floating-x"}>
              <img
                src={BubbleFileHire}
                alt={"bubble_filehire_image"}
                className={"mx-auto w-40 md:w-60"}
              />
            </div>
          </div>

          <div
            className={
              "flex flex-col lg:flex lg:flex-row justify-evenly mt-20 text-center pb-28"
            }
          >
            <div className={"relative"}>
              <div
                className={
                  "absolute w-60 md:-top-20 md:-left-20 lg:-top-20 lg:-left-20 floating"
                }
              >
                <img src={BubbleEducationSection} alt={"Bubble_Image"} />
              </div>
              <div className={"flex flex-col gap-10 lg:flex lg:flex-row "}>
                <CardEasy
                  title={"Graduado"}
                  image={Education}
                  description={"Da el primer paso en tu carrera profesional"}
                />
                <CardEasy
                  title={"Desempleado"}
                  image={findJob}
                  description={"Haz más rápida tu búsqueda de trabajo"}
                />
                <CardEasy
                  title={"Ejecutivo"}
                  image={money}
                  description={"Explorar nuevas oportunidades de crecimiento"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationLevelSection;
