import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  CommentSliderSection,
  EducationLevelSection,
  FindJobSection,
  Footer,
  Header,
  HeroSection,
  RegisterFormSection,
} from "../new-ui/components";
import { HeroWave } from "../new-ui/components/svgs";
import Airplane from "../images/avion_1.svg";

const NewUiIndexPage = () => (
  <Layout hasLoader={false}>
    {({ setIsShowingConfetti, isShowingConfetti }) => (
      <div>
        <SEO
          title="FileHire"
          description="Mass Job application. Aplica de forma instantanea a cientos de vacantes disponibles para ti. Agrega tus datos, aplica y mira como te llegan ofertas al instante."
        />
        <main className="bg-gradient-to-r flex from-[#120F19] to-[#363FAC] min-h-screen justify-between flex-col">
          <div
            className="pt-10 mx-auto w-[80%] relative"
            style={{
              zIndex: 999,
            }}
          >
            <Header />
          </div>
          <div className="lg:absolute h-full w-full flex justify-center items-center">
            <div
              className={
                "absolute  top-[65%]   w-[200px]  md:w-[250px] md:top-[70%] lg:top-[60%] lg:left-[40%]  lg:w-[300px] slideLeft"
              }
            >
              <img src={Airplane} alt={"airplane-animation"} />
            </div>
            <div className="w-[80%] mx-auto">
              <HeroSection />
            </div>
          </div>
          <HeroWave />
        </main>
        <section>
          <FindJobSection />
        </section>
        <section>
          <RegisterFormSection
            {...{ setIsShowingConfetti, isShowingConfetti }}
          />
        </section>
        <section>
          <CommentSliderSection />
        </section>
        <section>
          <EducationLevelSection />
        </section>
        <section>
          <Footer />
        </section>
      </div>
    )}
  </Layout>
);

export default NewUiIndexPage;
