import React from "react";
import Logo from "../../images/logo-header.svg";
import BubbleFooter from "../../images/burbuja_5.svg";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className={"bg-black text-white p-20 relative"}>
      <div className={"absolute w-40 -right-10 floating -top-10"}>
        <img src={BubbleFooter} alt={"Bubble_footer_image"} />
      </div>
      <div
        className={
          "w-[80%] mx-auto grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-32 text-center lg:text-start"
        }
      >
        <div className={"flex flex-col gap-6"}>
          <img
            src={Logo}
            alt={"logo footer"}
            className={"lg:w-full md:w-[300px] mx-auto"}
          />
          <div className={"flex flex-col"}>
            {/* aqui van las redes sociales */}
            <span className="lg:text-start text-2xl py-2">Siguenos en</span>
            <div className={"flex flex-col lg:flex lg:flex-row "}>
              <div
                className={
                  "flex items-end gap-2 justify-between md:justify-center"
                }
              >
                <a href={"https://www.instagram.com/file.hire/"} className={"text-4xl"}>
                  <FaInstagram style={{ fill: "white" }} />
                </a>
                <a href={"https://www.facebook.com/filehire"} className={"text-4xl "}>
                  <FaFacebookSquare style={{ fill: "white" }} />
                </a>
                <a href={"https://www.linkedin.com/company/filehire/"} className={"text-4xl "}>
                  <FaLinkedin style={{ fill: "white" }} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-col">
{/*            <a
              href="#registerForm"
              className={"text-2xl lg:text-start text-white"}
            >
              Nosotros
            </a>
            <a
              href="#registerForm"
              className={"text-2xl lg:text-start text-white"}
            >
              Blog
            </a>
            <a
              href="#registerForm"
              className={"text-2xl lg:text-start text-white"}
            >
              Contacto
            </a>*/}
            <a
              href="#registerForm"
              className={"text-2xl lg:text-start text-white"}
            >
              Regístrate
            </a>
            <a
              href="https://filehire.com/dash/login"
              className={"text-2xl lg:text-start text-white"}
            >
              Iniciar sesión
            </a>
          </div>
        </div>

        <div>
          <div className="flex flex-col">
            <a
              href="https://filehire.com/service"
              className={"text-2xl lg:text-start text-white"}
            >
              Términos y Condiciones
            </a>
            <a
              href="https://filehire.com/privacy"
              className={"text-2xl lg:text-start text-white"}
            >
              Aviso de Privacidad
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
