import React from "react";

const FindJobWave = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1996}
    height={150}
    fill="none"
    {...props}
  >
    <path
      fill="#B666B7"
      d="M755 59.676c-297.728 99.756-602.924 68.651-755 0v143.5h1995.5v-155c-152.12-2.647-202.03 6.216-445-23-310.65-36.428-484.82-40.011-795.5 34.5Z"
    />
  </svg>
);
export default FindJobWave;
